<template>
  <div class="upload_box">
    <el-upload 
      ref="upload"
      class="upload-demo"
      accept=".xls,.xlsx,.docx,.doc,.pdf,.html,.jpg,.PNG,.JPG,.JPEG,.png,.jpeg"
      :action="action"
      :headers="headers"
      :file-list="fileList"
      :before-upload="beforeAvatarUpload"
      :on-remove="removeFiles"
      :on-success="handleSuccess">
      <div><i class="el-icon-upload2"></i>上传文件</div>
      <div slot="tip" class="el-upload__tip">
        <div>支持xls/xlsx/docx/doc/pdf/html/jpeg/jpg/PNG/png/JPG/JPEG!且不超过50M</div>
        <div>{{addFileName}}</div>
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'uploads',
  props: ['refresh', 'disabled', 'picture', 'fileType',"files"],
  data () {
    return {
      action: this.BASE_URL + this.$url.PATH.annex,
      loading: false,
      imageUrl: '',
      addFileName: '',
      fileList: [],
      pathList: []
    }
  },
  computed: {
    headers () {
      return {
        Authorization: window.localStorage.getItem('token'),
        Loginsource: '1',
        Anotherplace: this.$url.PATH.Anotherplace
      }
    }
  },
  watch: {
    picture (val) {
      if (this.picture === undefined || this.picture === '' || this.picture === null) {
        this.imageUrl = ''
      } else {
        this.imageUrl = this.picture
      }
    },
    refresh (val) {
      if (this.picture === undefined || this.picture === '' || this.picture === null) {
        this.imageUrl = ''
      } else {
        this.imageUrl = this.picture
      }
    }
  },
  created () {
    if (this.files != undefined) {
      this.fileList = [...this.files]
    }
    if (this.picture === undefined || this.picture === '' || this.picture === null) {
      this.imageUrl = ''
    } else {
      this.imageUrl = this.picture
    }
  },
  methods: {
    beforeAvatarUpload(file) {
      let type = file.name.substring(file.name.lastIndexOf('.')+1)
      const isJPG = 'xls/xlsx/docx/doc/pdf/html/jpeg/jpg/PNG/png/JPG/JPEG'.includes(type);
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isJPG) {
        this.$message.error('上传文件类型为xls/xlsx/docx/doc/pdf/html/jpeg/jpg/PNG/png/JPG/JPEG!');return false;  
      }else if (!isLt50M) {
        this.$message.error('上传附件大小不能超过 50MB!');return false;
      }else{
        return isJPG && isLt50M;
      }
    },
    removeFiles(file, fileList) {
      // console.log(file.name)
      const arr = []
      this.fileList.forEach(item => {
        // console.log(item)
        if (item.name != file.name) {
          arr.push(item)
        }
      })
      this.fileList = arr
      // console.log(this.fileList)
    },
    handleSuccess(data,file) {
      let type = file.name.substring(file.name.lastIndexOf('.')+1)
      let obj = {
        name:　file.name,
        value: data.data.filepath
      }
      this.pathList.push(obj)
    },
    returnPath() {
      return this.pathList
    },
    clearFileList() {
      this.fileList = []
      this.pathList = []
    }
  }
}
</script>

<style scoped>
.upload-demo .el-upload > div{
  width: 105px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  background: #196DF7;
  text-align: center;
  color: #FFFFFF;
}
.upload-demo .el-upload__tip {
  display: inline-block;
  margin-left: 10px; 
  color: #ccc;
}
</style>
