<template>
  <div class="setting_main_bg">
    <div class="plan_cont">
      <div class="plan_box">
        <div class="btn_group" v-if="roleBtnArray[0].power">
          <div class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
        </div>
        <div class="flexAC" :class="roleBtnArray[0].power === true?'ut_top':''">
          <el-input v-model="planname"  placeholder="请输入搜索内容" class="contInput"></el-input>
          <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
          <el-button class="settingReset" @click="handleReset">重置</el-button>
        </div>
        <div class="plan_table">
          <el-table
            stripe
            :data="planList"
            style="width: 100%">
            <el-table-column
              label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              label="单位名称"
              prop="companyname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="预案标题"
              prop="doc_title"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="预案简介"
              prop="doc_content"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="执行时间"
              prop="doc_time"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="上传时间"
              prop="creatime">
            </el-table-column>
            <el-table-column
              label="是否驳回"
              prop="rejected">
              <template slot-scope="scope">
                <span v-if="scope.row.rejected == true" >是</span>
                <span v-if="scope.row.rejected == false" >否</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
                <el-button type="text" size="small" v-if="roleBtnArray[2].power" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="small" v-if="roleBtnArray[1].power" style="color:red;" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- 新增 -->
    <el-dialog title="预案新增" :visible.sync="addFromShow" width="900px" center class="gyDialog unit_addDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <div class="flexCB">
          <el-form-item label="单位名称" class="foItem"  prop="companyid" required>
<!--            <el-input v-model="addfrom.companyname"  placeholder="请输入单位名称" disabled></el-input>-->
            <el-select v-model="addfrom.companyid" placeholder="请选择单位" :disabled="isSuper" class="adSe" style="width: 100%">
              <el-option v-for="item in companyList" :key="item.index"
                         :label="item.companyname"
                         :value="item.companyid">
                {{ item.companyname }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预案标题"  class="foItem" prop="doc_title">
            <el-input v-model="addfrom.doc_title"  placeholder="请输入预案标题"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="预案简介" prop="doc_content">
          <el-input type="textarea" v-model="addfrom.doc_content" placeholder="请输入预案简介"></el-input>
        </el-form-item>
        <el-form-item label="执行时间" class="foItem"  prop="doc_time">
          <el-date-picker v-model="addfrom.doc_time" :picker-options="pickerOptions" value-format="yyyy-MM-dd"  type="date" placeholder="选择日期" style="width: 390px;"></el-date-picker>
        </el-form-item>
        <el-form-item class="upload_label" label="上传附件"  prop="doc_fire">
          <Uploads :fileType="'jpg/png/jpeg'" ref="upload"></Uploads>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog title="预案编辑" :visible.sync="editFromShow" width="900px" center class="gyDialog unit_addDialog" :close-on-click-modal="false" :before-close="editFromCancel">
      <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
        <div class="flexCB">
          <el-form-item label="单位名称" class="foItem"  prop="companyname" required>
            <el-input v-model="editfrom.companyname"  placeholder="请输入单位名称" disabled></el-input>
          </el-form-item>
          <el-form-item label="预案标题"  class="foItem" prop="doc_title">
            <el-input v-model="editfrom.doc_title"  placeholder="请输入预案标题"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="预案简介" prop="doc_content">
          <el-input type="textarea" v-model="editfrom.doc_content" placeholder="请输入预案简介"></el-input>
        </el-form-item>
        <el-form-item label="执行时间" class="foItem"  prop="doc_time">
          <el-date-picker v-model="editfrom.doc_time" value-format="yyyy-MM-dd" :picker-options="pickerOptions"  type="date" placeholder="选择日期" style="width: 390px;"></el-date-picker>
        </el-form-item>
        <el-form-item class="upload_label" label="上传附件" prop="doc_fire">
          <Uploads :fileType="'jpg/png/jpeg'" ref="upload" v-if="editFromShow" :files="editfrom.doc_fire"  @uploadSuccess="editImgSuccess" ></Uploads>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="预案详情" :visible.sync="detailShow" width="800px" center class="gyDialog unit_addDialog">
      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">预案基本信息</p>
          </div>
          <p class="udi_p">单位名称：{{ detailInfo.companyname }}</p>
          <p class="udi_p">预案标题：{{ detailInfo.doc_title }}</p>
          <p class="udi_p">预案简介：{{ detailInfo.doc_content }}</p>
          <div class="flexCB">
            <p class="udi_s">执行时间：{{ detailInfo.doc_time }}</p>
            <p class="udi_s">上传时间：{{ detailInfo.creatime }}</p>
          </div>
        </div>
      </div>

      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">预案附件</p>
          </div>
          <div v-for="(item, index) in detailInfo.doc_fire" :key="index">
            <div><i class="el-icon-paperclip" style="margin-right: 10px;"></i>{{ item.name }}<span class="down_t" @click="downDoc(item.name,item.value)">下载</span></div>
          </div>
        </div>
      </div>

      <div class="flexSC" v-if="detailInfo.rejected == true">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">驳回原因</p>
          </div>
          <p class="udi_p">{{ detailInfo.rej_mark }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getplanInfo, planAdd, planEdit, planDelete } from '@/http/api/plan'
import Uploads from '@/components/uploads.vue'
import { unitDownQuery } from '@/http/api/unit'
export default {
  name: 'planList',
  components: {
    Uploads
  },
  props: ['roleBtn'],
  data () {
    // const validateFile = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请上传附件'));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() > Date.now();
      //   }
      // },
      page: 1,
      size: 10,
      total: 0,
      planname: '',
      planList: [],
      addFromShow: false,
      addfrom: {
        companyid: '',
        doc_title: '',
        doc_content: '',
        doc_fire: [],
        doc_image: '',
        doc_time: ''
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      formRules: {
        companyid: [{ required: true, message: '请选择单位', trigger: 'blur' }],
        doc_title: [
          { required: true, message: '请输入预案名称', trigger: 'change' },
          { min: 1, max: 32, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        doc_content: [
          { required: true, message: '请输入预案说明', trigger: 'change' },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'change' }
        ],
        doc_fire: [
          { required: true, message: '请上传附件', trigger: 'change' }
        ],
        doc_time: [
          { required: true, message: '请选择执行时间', trigger: 'change' }
        ]
      },
      editFromShow: false,
      editfrom: {},
      editformRules: {
        doc_title: [
          { required: true, message: '请输入预案名称', trigger: 'change' },
          { min: 1, max: 32, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        doc_content: [
          { required: true, message: '请输入预案说明', trigger: 'change' },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'change' }
        ],
        doc_fire: [
          { required: false, message: '请上传附件', trigger: 'change' }
        ],
        doc_time: [
          { required: true, message: '请选择执行时间', trigger: 'change' }
        ]
      },
      detailShow: false,
      detailInfo: {},
      roleBtnArray: [],
      companyList: [],
      isSuper: false
    }
  },
  beforeMount () {
    if (this.roleBtn.length > 0) {
      this.roleBtnArray = this.roleBtn
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    // this.companyname = JSON.parse(window.localStorage.getItem('loginInfo')).companyname
    // this.addfrom.companyname = this.companyname
    this.addfrom.companyid = this.companyid
    if (this.companyid === '') {
      this.isSuper = false
    } else { this.isSuper = true }
    this.addfrom.doc_time = this.getCurrentDate()
    this.DropDown()
    this.init()
  },
  methods: {
    DropDown () {
      // 单位选择下拉框
      unitDownQuery().then(res => {
        if (res.status === '1') {
          this.companyList = res.data
        } else {
          this.companyList = []
        }
      })
    },
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        companyid: this.companyid,
        planname: this.planname,
        rejected: this.rejected
      }
      getplanInfo(params).then(res => {
        if (res.status === '1') {
          this.planList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.planList = []
          this.total = 0
        }
      })
    },
    getCurrentDate () {
      var mydate = new Date()
      var year = mydate.getFullYear() + ''
      var month = '' + (mydate.getMonth() + 1)
      month = month.length === 1 ? ('0' + month) : month
      var day = '' + mydate.getDate()
      day = day.length === 1 ? ('0' + day) : day
      var str = year + '-' + month + '-' + day
      return str
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    downDoc (name, path) {
      let link = document.createElement('a')
      link.style.display = 'none'
      link.setAttribute('download', name)
      link.href = path
      document.body.appendChild(link)
      link.click()
      // window.open(this.BASE_URL + '/uploads/documents/' + row.doc_fire, '_blank')
    },
    handleAdd () {
      this.addFromShow = true
    },
    AddFromConfirm () {
      const data = this.$refs['upload'].pathList
      if (data.length <= 0) {
        this.addfrom.doc_fire = ''
      } else {
        this.addfrom.doc_fire = data.length.toString()
      }
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          companyid: this.addfrom.companyid,
          doc_title: this.addfrom.doc_title,
          doc_content: this.addfrom.doc_content,
          doc_fire: data,
          // doc_image: this.addfrom.doc_image,
          doc_time: this.addfrom.doc_time
        }
        planAdd(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.addFromShow = false
      this.$refs['addFrom'].resetFields()
      this.$refs['upload'].clearFileList()
    },

    handleDetail (row) {
      this.detailShow = true
      this.detailInfo = row
    },
    editImgSuccess (res) {
      this.editfrom.doc_fire = res.filepath
    },
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = { ...row }
    },
    editFromConfirm () {
      const data = this.$refs['upload'].pathList
      const files = this.$refs['upload'].fileList
      let arr = []
      if (data.length <= 0) {
        if (files.length <= 0) {
          this.editfrom.doc_fire = ''
        } else {
          arr = [...files]
          this.editfrom.doc_fire = this.editfrom.doc_fire.length.toString()
        }
      } else {
        if (files.length <= 0) {
          arr = [...data]
        } else {
          arr = [...data, ...files]
        }
        this.editfrom.doc_fire = data.length.toString()
      }
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          documentsid: this.editfrom.documentsid,
          companyid: this.editfrom.companyid,
          doc_title: this.editfrom.doc_title,
          doc_content: this.editfrom.doc_content,
          doc_fire: arr,
          doc_time: this.editfrom.doc_time
        }
        planEdit(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
      this.$refs['upload'].clearFileList()
    },
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        planDelete({ documentsid: row.documentsid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleReset () {
      this.page = 1
      this.planname = ''
      this.init()
    }
  }
}
</script>

<style scoped>
.plan_cont{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.plan_box{
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.ut_top,.plan_table {
  margin-top: 20px;
}
.foItem{
  width: 390px;
}
.el-input__icon .el-icon-date {
  line-height: 25px;
}
.udi_k {
  width: 4px;
  height: 17px;
  background: #196DF7;
  opacity: 1;
}
.udi_t {
  color: #333333;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}
.ud_left{
  width: 520px;
}
.ud_left{
  width: 700px;
}
.udi_p,.udi_s{
  width: 100%;
  background: #F6F9FF;
  color: #333333;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.udi_s{
  width: 48%;
}
.down_t {
  font-size: 14px;
  font-family: 'PingFangSC-Regular';
  line-height: 22px;
  color: #196DF7;
  opacity: 1;
  margin-left: 20px;
  cursor: pointer;
}
.el-tooltip {
  max-width:20%;
  background: #fff;
}
</style>
